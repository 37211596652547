import React, { useEffect } from "react";
import SubPage from "../../Common/SubPage";
import BodyContent from "../../Common/BodyContent";
import ScrollToTop from "../../Common/HOC/ScrollToTop";

const PrivacyPolicy = () => {
    const properties = {
        titleBlack: "Privacy Policy",
    };

    useEffect(() => {
        const script1 = document.createElement("script");
        script1.src = "https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js";
        script1.type = "text/javascript";
        script1.charset = "UTF-8";
        script1.id = "otprivacy-notice-script";
        script1.setAttribute("settings", "eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyIsImNvbnRlbnRBcGlVcmwiOiJodHRwczovL3ByaXZhY3lwb3J0YWwub25ldHJ1c3QuY29tL3JlcXVlc3QvdjEvZW50ZXJwcmlzZXBvbGljeS9kaWdpdGFscG9saWN5L2NvbnRlbnQiLCJtZXRhZGF0YUFwaVVybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9lbnRlcnByaXNlcG9saWN5L2RpZ2l0YWxwb2xpY3kvbWV0YS1kYXRhIn0=");
        document.body.appendChild(script1);

        const script2 = document.createElement("script");
        script2.type = "text/javascript";
        script2.charset = "UTF-8";
        script2.innerHTML = `
          OneTrust.NoticeApi.Initialized.then(function() {
            OneTrust.NoticeApi.LoadNotices([
              "https://privacyportal-cdn.onetrust.com/storage-container/b1e76efe-5a14-4bc5-a879-9238f67bce9a/privacy-notices/23d6c9f3-f79f-42ea-9c85-3344dd5d24e8/published/privacynotice.json"
            ]);
          });
        `;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    return (
        <SubPage properties={properties}>
            <BodyContent>
                <div id="otnotice-23d6c9f3-f79f-42ea-9c85-3344dd5d24e8" className="otnotice"></div>
            </BodyContent>
        </SubPage>
    );
};

export default ScrollToTop(PrivacyPolicy);
