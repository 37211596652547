import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import {
  GET_ANALYTICS_URL_DATA_REQUEST,
  GET_ANALYTICS_URL_DATA_SUCCESS,
  GET_ANALYTICS_URL_DATA_ERROR
} from "../../../redux/actions/type";


export const getAnalyticsUrl = {
  path: "/analyticsUrl",
  request: GET_ANALYTICS_URL_DATA_REQUEST,
  success: GET_ANALYTICS_URL_DATA_SUCCESS,
  error: GET_ANALYTICS_URL_DATA_ERROR,
};

export default function Analytics() {
  const dispatch = useDispatch();
  const { data: analyticsUrl } = useSelector(
    ({ analytics }) => analytics
  );

  useEffect(() => {
    if (analyticsUrl === undefined || analyticsUrl === null) {
      dispatch(actions.commonAction(getAnalyticsUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let finalAnalyticsUrl = analyticsUrl?.data?.analyticsUrl;

    if (finalAnalyticsUrl !== undefined && analyticsUrl !== null) {
      let script_component = document.getElementById("analyticsScript");
      if (script_component === null) {
        script_component = document.createElement('script');
        script_component.setAttribute('src', finalAnalyticsUrl);
        script_component.setAttribute('id', 'analyticsScript');
        script_component.setAttribute('async', true);
        script_component.setAttribute('type', 'text/plain');
        script_component.setAttribute('class', 'optanon-category-C0002-C0003-C0004');
        document.head.appendChild(script_component);
      }
    }

  }, [analyticsUrl]);
}
